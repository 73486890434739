import { Alert, Button, Grid, TextField } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from "formik";
import React, {useCallback,useState, useEffect} from "react";
import { useAuth } from "../../context/context";
import Logo from "../../images/iou_financial_logo.png";
import { Link, useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "200px",
      padding: "20px 20px 20px 20px",
      backgroundColor: "#fff",
    },
    mainLogo: {
      marginBottom: "10px",
      width: "100%",
    },
    inputField: {
      padding: "20px",
    },
    submitButton: {},
    alert: { marginBottom: "20px" },
  })
);

const Login = () => {
  const classes = useStyles();
  const { signIn, verifyRecaptcha, recaptchaSignIn, triggerRefreshToken } = useAuth();
  const { state } = useLocation();
  const { notice, alert } = state || {};

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = (values, { setErrors, setSubmitting }) => {
    setTimeout(() => {
      const { email, password } = values;
      return executeRecaptcha('sign_in').then(
        (token) => {
          return recaptchaSignIn(token, email, password)
    })
        .catch((error) => {
          setErrors({
            password: "Invalid password",
            email: "Invalid email address",
          });
          console.log(error);
        })
        .then(() => {
          triggerRefreshToken()
          setSubmitting(false);
        });
    }, 400);
  };

  const validation = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    }
    return errors;
  };

  return (
    <Grid container spacing={0} align="center" justifyContent="center">
      <Grid item className={classes.root}>
        {notice && (
          <Alert severity="info" className={classes.alert}>
            {notice}
          </Alert>
        )}
        {alert && (
          <Alert severity="error" className={classes.alert}>
            {alert}
          </Alert>
        )}

        <img className={classes.mainLogo} width="100%" src={Logo} alt="IOU Financial Logo" />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validate={validation}
          onSubmit={submit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Grid>
              <form data-cy="login-form" onSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid container item sm={6}>
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      placeholder="Enter email address"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={errors.email && touched.email && errors.email}
                      autoComplete="current-email"
                      variant="outlined"
                      InputLabelProps={{ shrink: true, style: { color: "#000000" } }}
                    />
                  </Grid>
                  <Grid container item sm={6} justifyContent="stretch" flexDirection="column">
                    <TextField
                      id="password"
                      fullWidth
                      label="Password"
                      type="password"
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      helperText={errors.password && touched.password && errors.password}
                      autoComplete="current-password"
                      variant="outlined"
                      InputLabelProps={{ shrink: true, style: { color: "#000000" } }}
                    />
                    <Grid item textAlign="right">
                      <Link to="/forgotPassword">forgot password?</Link>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Button
                      className="button-primary"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Login;
