import React, { useCallback, useEffect, useState, useContext } from "react";
import { apiCall, clearSessionCSRF } from "../shared/api";
import { fetchEnv } from "../env";
export const AuthStateContext = React.createContext({});

async function logout() {
  await apiCall('DELETE', "/sign_out");
}

// Authorizes with Fireball using the Platform token
async function authorize(email, password) {
  try {
    const resp = await apiCall('POST', "/sign_in", {
      data: {
        user: { email, password },
      },
      headers: {
        Accept: 'application/json'
      }
    });
    return { user: resp.data };
  } catch (err) {
    if (err?.response?.status !== 401) {
      console.error("Authorize error:", err);
    }
    throw "Unauthorized";
  }
}

export async function sendResetPasswordInstructions(email) {
  const resp = await apiCall('POST', "/users/password", {
    data: {
      user: { email },
    },
    headers: {
      Accept: 'application/json'
    }
  });
  return resp.data;
}

export async function resetPassword(password, password_confirmation, reset_password_token) {
  const resp = await apiCall('PUT', "/users/password", {
    data: {
      user: {
        reset_password_token,
        password,
        password_confirmation
      }
    },
    headers: {
      Accept: 'application/json'
    }
  });
  return resp.data;
}

// Verifies the user's session
async function verify() {
  const resp = await apiCall('POST', "/auth/verify");
  return { user: resp.data.user };
}

async function masqueradeAs(broker) {
  document.location.replace(`/auth/masquerade/${broker.id}`)
}

async function demasquerade() {
  document.location.replace('/auth/masquerade/back')
}

export const AuthProvider = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [user, _setUser] = useState(null);
  const [refreshToken, setRefreshToken] = useState(false);

  const triggerRefreshToken = useCallback(() => {
    setRefreshToken(!refreshToken);
  });

  const setUser = (user) => {
    _setUser(user);
    setLoggedIn(true);
  }

  const signOut = useCallback(() => {
    logout().finally(() => {
      setLoggedIn(false);
      clearSessionCSRF();
      _setUser(null);
      // Need to do this to make sure we update CSRF values after logout
      window.location.replace(`${fetchEnv('BASE_PATH')}/login`)
    })
  }, []);

  const signIn = useCallback(async (email, password) => {
    setLoading(true);
    try {
      const { user } = await authorize(email, password);
      setUser(user);
    } finally {
      setLoading(false);
    }
  }, []);

  const recaptchaSignIn = useCallback(async (token, email, password) => {
    setLoading(true);
    try {
      const response = await verifyRecaptcha(token);
      if (response.data.success) {
        const { user } = await authorize(email, password);
        setUser(user);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const verifyRecaptcha = useCallback(async (token) => {
    return await apiCall('POST', "/verify_cap", {
      data: { token }
    });
  }, []); 

  useEffect(() => {
    (async () => {
      try {
        const { user } = await verify();
        setUser(user);
      } finally {
        clearSessionCSRF();
        setInitializing(false);
      }
    })()
  }, []);

  return (
    <AuthStateContext.Provider
      value={{
        initializing,
        loading,
        loggedIn,
        verifyRecaptcha, recaptchaSignIn,
        signIn, signOut,
        refreshToken, triggerRefreshToken,
        masqueradeAs, demasquerade,
        isMasquerading: !!user?.masquerading,
        user
      }}>
      {children}
    </AuthStateContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthStateContext);
}